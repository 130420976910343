<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Kategorie - seřadit</strong>
      </div>
      <action-tools :routerOptions="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6" v-if="treeData.length !== 0">
          <a-button @click="handleCollapseAll">Sbalit vše
            <a-icon type="shrink"/>
          </a-button>
          <a-button @click="handleExpandAll" class="ml-3">Rozbalit vše
            <a-icon type="arrows-alt"/>
          </a-button>
        </div>
      </div>
      <a-form class="login-form" :form="form" @submit="handleSubmit">
        <a-form-item>
          <a-tree
            @drop="handleDrop"
            draggable
            :treeData="treeData"
            :expandedKeys="expandedKeys"
            class="draggable-tree"
            @expand="handleExpand"
          >
          </a-tree>
          <a-empty v-if="treeData.length === 0" />
        </a-form-item>

        <div class="form-actions">
          <a-button
            :disabled="loading"
            type="primary"
            htmlType="submit"
          >Seřadit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      form: this.$form.createForm(this),
      treeData: [],
      loading: false,
      expandedKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/ecommerce/category/add',
          title: 'Přidat',
        },
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/category',
          title: 'Seznam',
        },
      ],
    }
  },
  computed: {
    categories: function () {
      return this.$store.getters['category/currentLanguage']
    },
  },
  methods: {
    handleExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
    },
    handleExpandAll() {
      this.expandedKeys = this.treeData.map(x => x.key)
    },
    handleCollapseAll() {
      this.expandedKeys = []
    },
    handleSubmit(e) {
      e.preventDefault()
      this.loading = true
      this.$store.dispatch('category/putSort', this.getData(this.treeData))
        .then(() => {
          this.handleRefresh()
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    getData(data) {
      return data.map(x => {
        return {
          cey_id: x.key,
          children: this.getData(x.children),
        }
      })
    },
    toTreeNodeData(data) {
      return data.map(x => {
        return {
          key: x.cey_id,
          title: '#' + x.cey_id + ' | ' + (x.languages[0] !== undefined ? x.languages[0].pivot.cle_name : 'není nenastaveno') + (x.cey_is_active === false ? ' [neaktivní]' : ''),
          children: this.toTreeNodeData(x.all_children),
          selectable: false,
        }
      })
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('category/getList')
        .catch(() => {})
        .finally(() => {
          this.treeData = this.toTreeNodeData(this.categories)
          this.loading = false
        })
    },
    loop(data, key, callback) {
      return data.forEach((item, index, arr) => {
        if (item.key === key) {
          return callback(item, index, arr)
        }
        if (item.children !== undefined && item.children.length > 0) {
          return this.loop(item.children, key, callback)
        }
      })
    },
    handleDrop(info) {
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey

      let dragObj
      let spliceObjArr
      let spliceObjIndex
      let shouldSplice = true
      this.loop(this.treeData, dragKey, (item, index, arr) => {
        spliceObjArr = arr
        spliceObjIndex = index
        dragObj = item
      })
      if (!info.dropToGap) {
        if ((dragObj.children || []).length > 0) {
          return false
        }
        shouldSplice = false
        this.loop(this.treeData, dropKey, (item) => {
          spliceObjArr.splice(spliceObjIndex, 1)
          item.children = item.children || []
          item.children.push(dragObj)
        })
      } else {
        let ar
        let i
        this.loop(this.treeData, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        spliceObjArr.splice(spliceObjIndex, 1)
        shouldSplice = false
        ar.splice(i, 0, dragObj)
      }
      if (shouldSplice) {
        spliceObjArr.splice(spliceObjIndex, 1)
      }
    },
  },
  created() {
    if (this.treeData.length === 0 && this.categories.length !== 0) {
      this.treeData = this.toTreeNodeData(this.categories)
    }
    if (this.categories.length === 0 && this.$store.getters['language/active'] !== null) {
      this.handleRefresh()
    }
    this.$store.watch(
      (state, getters) => getters['language/active'], () => {
        if (this.$store.getters['language/active'] !== null && this.categories.length === 0) {
          this.handleRefresh()
        }
      },
    )
  },
  watch: {
    categories() {
      this.treeData = this.toTreeNodeData(this.categories)
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
